/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";

@import "~bootstrap/dist/css/bootstrap.min.css";
// body{
//   width: max-content;
// }
// html{
//   width: max-content;
// }

.igx-grid__td span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.igx-navbar {
  background-color: #70a9d0 !important;
}
app-igxgrid1 {
  width: 100%;
}

igx-grid-header {
  background-color: #6ea3cc;
  background-repeat: repeat-x;
  background-image: url(../src/assets/igg_header.gif);

  border-top: 1px solid #a0c3de;
  border-right: 1px solid #5593c3;
  border-bottom: 1px solid #5593c3;
  border-left: 1px solid #a0c3de;
}
// overriding the default column header height of igx-grid
.igx-grid__th-title {
  //line-height: 8 !important;
  opacity: 1 !important;
}

igx-grid-header span span {
  font-size: 12px !important;
  padding: 3px 0 3px 8px !important;
  overflow: hidden !important;
  text-align: left !important;
  color: White !important;
  font-family: Verdana, Arial, Helvetica !important;

  // // // white-space: nowrap;
  // // // text-overflow: ellipsis;
  // // // overflow: hidden;
  // // font-size: 12px;
  // // font-weight: bolder;
  // // font-family: Arial, Helvetica, sans-serif;
  // word-spacing: 100vw;
  // margin: auto;
  // white-space: pre-line !important;
}

.lblCellName {
  font-family: Verdana, Arial, Helvetica;
  font-size: 11px;
  line-height: 16px;
  margin: 0px;
  padding: 5px;
  vertical-align: top;
  width: 100px;
}

// .lblCellFormula {
//   font-family: Verdana, Arial, Helvetica;
//   font-size: 11px;
//   line-height: 16px;
//   margin: 0px;
//   padding: 5px;
//   vertical-align: top;
// }

html {
  scroll-behavior: smooth;
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto-Thin.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Thin.woff") format("woff"),
    url("./assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Black.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Black.woff") format("woff"),
    url("./assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Light.woff") format("woff"),
    url("./assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Medium.woff") format("woff"),
    url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
/** Material Icon font **/
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  // src: url(./assets/fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: url("./assets/fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("./assets/fonts/MaterialIcons-Regular.woff") format("woff"),
    url("./assets/fonts/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.table-material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
  vertical-align: middle;
}
.noscroll {
  overflow: hidden;
}

.pagetitle {
  font-family: "Roboto-Light";
  font-size: 28px;
  color: #004b87;
  text-align: left;
}
.pagetitleSecondLayer {
  font-family: "Roboto-Light";
  font-size: 20px;
  color: #333;
  text-align: left;
}
.titleLight {
  font-family: "Roboto-Light";
  font-size: 14px;
  color: #76797b;
  text-align: left;
}
.titleBold {
  font-family: "Roboto-Bold";
  font-size: 14px;
  color: #76797b;
  text-align: left;
}
.error {
  font-size: 14px;
  color: red;
}
.note {
  font-size: 13px;
  color: #76797b;
}
.pagetitleSecondLayerBold {
  font-family: "Roboto-Bold";
  font-size: 20px;
  color: #333;
  text-align: left;
}
.pagetitlesubtext {
  padding-top: 2px;
  font-family: "Roboto-Regular";
  font-size: 14px;
  text-align: left;
}

.dropdown-label {
  font-size: 13px;
  padding: 10px 5px 1px 5px;

  z-index: 1;
  font-family: "Roboto-Regular", "Roboto";
  font-weight: 400;
  font-style: normal;
  color: #76797b;
  pointer-events: none;
}

.lib-text-field[required] {
  .lib-placeholder::after {
    content: " (required)";
  }

  input:focus + .lib-placeholder::before,
  input:not(:placeholder-shown) + .lib-placeholder::before {
    content: "* ";
  }
  input:focus + .lib-placeholder::after,
  input:not(:placeholder-shown) + .lib-placeholder::after {
    content: "";
  }
}

.lib-text-field {
  margin: 20px 0;
  position: relative;
  display: block;
  .lib-placeholder {
    padding: 5px 5px 1px 5px;
    font-size: 16px;
    z-index: 9;
    background: none;
    font-family: "Roboto-Regular", "Roboto";
    font-weight: 400;
    font-style: normal;
    color: #76797b;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
  }

  input {
    padding: 10px 5px 1px 5px;
    width: calc(100% - 10px);
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #878787;
    position: relative;
    background: none;
    z-index: 10;
    line-height: 1.6;
    font-size: 16px;
    text-align: left;
    font-family: "Roboto-Regular", "Roboto";
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }

  input:focus {
    outline-width: 0;
    box-shadow: 0 2px 0 #00a9e0;
    border-color: #00a9e0;
  }

  input:focus + .lib-placeholder,
  input:not(:placeholder-shown) + .lib-placeholder {
    opacity: 1;
    transform: translateY(-17px) translateX(-5px);
    font-size: 13px;
  }

  /* For IE Browsers*/
  input:focus + .lib-placeholder,
  input:not(:-ms-input-placeholder) + .lib-placeholder {
    opacity: 1;
    transform: translateY(-17px) translateX(-5px);
    font-size: 13px;
  }
}

/* Table css */

.table td {
  border-bottom: 1px solid #d3d4d5 !important;
  border-top: 0px !important;
  padding: 6px !important;
  font-family: "Roboto-Regular";
  font-size: 12px;
  text-align: left;
  color: #333333;
  height: 35px;
  vertical-align: middle;
}

// td:nth-child(2) {
//   color: #00a9e0 !important;
// }
// td:nth-child(7) {
//   color: #00a9e0 !important;
// }
.table thead th {
  border-bottom: 4px solid #ddebf0 !important;
  font-size: 14px !important;
  font-family: "Roboto-Bold" !important;
  color: #333333;
}
.table-hover tbody tr:hover td {
  background-color: #f1f1f1;
}

.table th {
  padding: 10px 10px 10px 5px !important;
  border-top: 0px !important;
}
.locked {
  color: #d10025;
  cursor: pointer;
  text-decoration: underline;
  .table-material-icons {
    color: #d10025;
  }
}
.lockedCust {
  color: #d10025;
  .table-material-icons {
    color: #d10025;
  }
}

.verified {
  color: #2f9033;
  cursor: pointer;
  text-decoration: underline;
  .table-material-icons {
    color: #2f9033;
  }
}
.verifiedCust {
  color: #2f9033;
  .table-material-icons {
    color: #2f9033;
  }
}

.delete_disabled {
  color: gray;
}
.delete {
  color: #00a9e0;
  cursor: pointer;
  font-size: 12px;
  font-family: "Roboto-Regular", "Roboto";
}
.export {
  color: #00a9e0;
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto-Regular", "Roboto";
}
.edit {
  color: #00a9e0;
  cursor: pointer;
  font-size: 12px;
  font-family: "Roboto-Regular", "Roboto";
}
.table_no_data_found {
  font-size: 13px;
  font-family: "Roboto-Regular", "Roboto";
  border-bottom: 1px solid #ccc;
  color: #00a9e0;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
}
.optionHeader {
  font-size: 16px;

  font-family: "Roboto-Regular", "Roboto";
  font-weight: 400;
  font-style: normal;
  color: #76797b;

  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
}

.toast-body {
  font-family: "Roboto-Bold";
  font-size: 14px;
  color: #00b16a;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #d4edda;
  border: 1px solid;
  border-radius: 2px;
  border-color: #78c78a;
}

.PageLevelError {
  width: 100%;
  font-family: "Roboto-Regular", "Roboto";
  font-size: 14px;
  color: #da291c;
  background: #f8d4d2;
  border: 1px solid #f0a9a4;
  border-radius: 2px;
  text-align: left;
  padding: 10px;
  .table-material-icons {
    color: #da291c;
  }
}
.information {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #cce1ed;
  border: 1px solid;
  border-color: #1f78b4;
  padding: 15px;
  border-radius: 2px;
  font-family: "Roboto-Regular", "Roboto";
  font-size: 13px;
  color: #76797b;
  line-height: 3px;
}
.messagetitle {
  font-family: "Roboto-Medium", "Roboto";
  color: #1f78b4;
  line-height: 28px;
  font-size: 15px;
}
.inputMsg {
  background: #f8d4d2;
  font-family: "Roboto-Light", "Roboto";
  font-size: 12px;
  color: #da291c;
  padding: 5px;
  .table-material-icons {
    color: #da291c;
  }
}
.table-material-icons {
  color: #004b87;
  font-size: 18px;
}

.table-hover tbody tr td:hover {
  .delete {
    .table-material-icons {
      color: #00a9e0;
    }
  }
  .edit {
    .table-material-icons {
      color: #00a9e0;
    }
  }
}

.table-hover thead tr th:hover {
  .table-material-icons {
    color: #00a9e0;
  }
}
.modal-backdrop.show {
  opacity: 0;
}

.myCustomModalClass .modal-dialog {
  max-width: 965px;
}

.smallModal .modal-dialog {
  max-width: 250px;
}
.finalSubmissionModal .modal-dialog {
  max-width: 610px;
}
.systemfailcontainer {
  background-color: #e5e5e6;
  border-top: 2px solid;
  border-color: #54585a;
  padding: 15px;
  border-radius: 0px 0px 2px 2px;
  font-family: "Roboto-Regular", "Roboto";
  font-size: 13px;
  color: #333333;
  line-height: 3px;

  .systemfailtitle {
    font-family: "Roboto-Bold", "Roboto";
    color: #333333;
    line-height: 28px;
    font-size: 15px;
  }
  .systemfailbody {
    font-family: "Roboto-Medium", "Roboto";
    color: #76797b;
    line-height: 28px;
    font-size: 13px;
  }
  .supportcontact {
    font-family: "Roboto-Medium", "Roboto";
    font-size: 13px;
    color: #333333;
    line-height: 20px;
  }
  .contactbody {
    font-family: "Roboto-Regular", "Roboto";
    color: #76797b;
    font-size: 13px;
    span {
      color: #00a9e0;
      text-decoration: underline;
    }
  }
}

.tooltip-class .tooltip-inner {
  background-color: #00a9e0;
  font-family: "Roboto-Medium", "Roboto";
  font-size: 13px;
}

.tooltip-class .arrow::before {
  border-bottom-color: #00a9e0;
}

@media print {
  .non-printable {
    display: none !important;
  }
  .lib-body {
    width: 100% !important;
  }
  .printable {
    display: block;
  }
  .lib-content,
  .lib-body {
    border: 0px !important;
  }
  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }
}

.newButton{
  padding: 4px 10px 4px 10px;
  background-color: #006ba6;
  //font-family: "Roboto-Medium", "Roboto";
  font-family: "arial";
  font-size: 12px;
  color: white;
  border-radius: 3px;
  border: 1px solid rgb(40, 75, 121);
  margin-bottom: 20px;
}

.cnfrm-btn{
  padding: 4px 10px 4px 10px  !important;
  background-color: #006ba6;
  //font-family: "Roboto-Medium", "Roboto";
  font-family: "arial";
  font-size: 12px !important;
  color: white;
  border-radius: 3px;
  border: 1px solid rgb(40, 75, 121);
  width:72px;

}
.swal2-popup{
  width:auto  !important;
}
.swal2-styled:focus {
  box-shadow: 0 0 0 1px rgb(0 0 0 / 83%) !important;
}

.swal2-content{
  font-family: "arial" !important;
  font-size: 16px  !important;

}

.filterBox {
  width: auto;
  padding: 10px;
  margin: auto;
  //  border: 1px solid rgb(167, 194, 209);
  //background-color: #E9E7FD;
  background-color: #eeeeef;
  margin-bottom: 20px;
  //  border-radius: 5px;
   font-family: "arial";
    font-weight: 400;
    font-style: normal;
    color: #333333;
    font-size: 14px;
    height: auto;
    overflow: hidden;
}

.filterBox label {
  padding-right: 5px;
  // width: 119px;
  font-size: 12px !important;
}

.selectInput {
  width: 145px;
  //height: 25px;
  height: 29px;
  padding: 6px;
  color: #444444;
  border: 1px solid rgb(192, 192, 192);
  background-color: white;
  margin-right: 20px;
  border-radius: 3px;
  font-size: 12px;
}

.selectLongInput {
  width: 275x;
  height: 29px;
  //height: 25px;
  padding: 6px;
  color: #444444;
  border: 1px solid rgb(192, 192, 192);
  background-color: white;
  margin-right: 25px;
  border-radius: 3px;
  font-size: 12px;
}

.selectShortInput {
  width: 78px;
  //height: 25px;
  height: 29px;
  padding: 6px;
  color: #444444;
  border: 1px solid rgb(192, 192, 192);
  background-color: white;
  margin-right: 25px;
  border-radius: 3px;
  font-size: 12px;
}
.selectShortInput1 {
  width: 70px;
  //height: 25px;
  height: 29px;
  padding: 6px;
  color: #444444;
  border: 1px solid rgb(192, 192, 192);
  background-color: white;
  margin-right: 25px;
  border-radius: 3px;
  font-size: 12px;
}

.rpt {
  margin-bottom: 0px !important;
  // margin-top: 10px !important;
  // float: right;
  // margin-right: 0px;
}
.inputcontainer {
  // min-width: 24%;
  // max-width: 49%;
  width: auto;
  float: left;
  // padding-right: 20px;
}
.header-box tr {
  line-height: 15px;
}
.header-box .header-bow-row {
  line-height: 12px;
}
.grid-total-row td {
  height: 19px;
  line-height: 1;
}

.rprtHeader {
  //font-family: Arial, Helvetica, sans-serif;
  font-family: "arial";
  font-size: 20px;
  //color: #2b2b2b;
  color: #004b87;
  //font-weight: bold;
  // padding-bottom: 10px;
  text-align: center;
  width: 100%;
  margin: auto;
}

.rprtHeaderSub {
  //font-family: Arial, Helvetica, sans-serif;
  font-family:"arial";
  font-size: 14px;
  //color: #2b2b2b;
  color: #004b87;
  //font-weight: bold;
  width: 100%;
  padding-bottom: 5px;
  padding-left: 10px;
}

#filter-group {
  width: 190px !important;
}
